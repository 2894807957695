<template>
  <loader :is-loading="documentTemplatesStore.getAllIsLoading" />
  <lf-table
    :columns="columns"
    :data="documentTemplatesStore.documentTemplates"
    :metadata="documentTemplatesStore.searchMeta"
    @change-page="changePage"
    @change-items-per-page="changePerPage"
  />
  <document-template-modal
    v-if="showCreateModal"
    mode="create"
    @close="showCreateModal = false"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useDocumentTemplatesStore } from "@/stores/documentTemplates";
import { useI18n } from "vue-i18n";
import compact from "lodash/compact";
import { useRouter, useRoute } from "vue-router";
import { getParamsWithPagination } from "@/helpers/pagination";
import DocumentTemplateActionButton from "./DocumentTemplateActionButton.vue";
import DocumentTemplateModal from "./DocumentTemplateModal.vue";
import LfTable from "@/components/ui/table/LfTable.vue";
import DocumentTemplateDocumentTags from "./DocumentTemplateDocumentTags.vue";
import DateAndTimeDisplay from "@/components/ui/text/DateAndTimeDisplay.vue";
import Loader from "@/components/ui/Loader.vue";
import type { IDealsColumn } from "@/models/applications";
import type { LocationQueryRaw } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const documentTemplatesStore = useDocumentTemplatesStore();

const page = ref(Number(route.query?.page ?? 1));
const perPage = ref(Number(route.query?.per_page ?? 15));
const showCreateModal = ref(false);

const search = computed(() => (route.query?.search as string) ?? "");

const columns = computed<IDealsColumn[]>(() =>
  compact([
    {
      label: t("DOCUMENT_TEMPLATES.TABLE.TEMPLATE_NAME"),
      key: "name"
    },
    {
      label: t("COMMON.DOCUMENTS"),
      key: "name",
      component: DocumentTemplateDocumentTags
    },
    {
      label: t("COMMON.ORGANIZATION"),
      key: "client.name",
      placeholder: t("COMMON.LENDFLOW")
    },
    {
      label: t("COMMON.DESCRIPTION"),
      key: "description"
    },
    {
      label: t("COMMON.UPDATED"),
      key: "updated_at",
      component: DateAndTimeDisplay
      // formatter: (value: string) =>
      //   formatDateCustom(value, "MMMM dd, yyyy hh:mm aa", true)
    },
    {
      label: t("COMMON.ACTIONS"),
      key: "actions",
      component: DocumentTemplateActionButton
    }
  ])
);

const changePage = (newPage: number) => {
  page.value = newPage;
};

const changePerPage = (newPerPage: number, maxPage: number) => {
  if (maxPage) {
    page.value = maxPage;
  }
  perPage.value = newPerPage;
};

watch(
  () => [page.value, perPage.value, search.value],
  async ([newPageValue, newPerPageValue, newSearchValue]) => {
    const params = getParamsWithPagination(
      { search: newSearchValue ?? null },
      newPageValue as number,
      newPerPageValue as number
    );

    await router.push({
      query: {
        ...route.query,
        ...params
      } as LocationQueryRaw
    });
    await documentTemplatesStore.getAll(params);
  },
  { immediate: true }
);
</script>
